<template>
  <section class="info-center">
    <a-tabs v-model:activeKey="activeKey" class="message" @change="changeActive">
      <a-tab-pane key="0" tab="全部"></a-tab-pane>
      <a-tab-pane key="1" tab="通知" force-render></a-tab-pane>
      <a-tab-pane key="2" tab="预警"></a-tab-pane>
    </a-tabs>
    <div class="info-main">
      <section class="form-style" style="margin-top: 0x">
        <a-form layout="inline">
          <a-form-item label="状态：">
            <a-select v-model:value="tabType" class="form-select" placeholder="请选择">
              <a-select-option v-for="item in tabList" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="compact">
            <a-input-group compact>
              <a-select v-model:value="businessType" style="width: 130px;">
                <a-select-option v-for="item in businessTypeList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-input v-model:value="searchText" style="width: 227px;" placeholder="请输入" allowClear />
            </a-input-group>
          </a-form-item>
          <a-form-item class="form-btns">
            <a-button @click="resetList" class="form-btn-reset">重置</a-button>
            <a-button @click="searchList" class="form-btn">查询</a-button>
          </a-form-item>
        </a-form>
      </section>
      <section class="table-style">
        <div class="table-head">
          <span class="title">消息列表</span>
        </div>
        <a-table
          :columns="warningColumns"
          :dataSource="dataSource"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="tableLoading"
          :rowKey="
            (record, index) => {
              return index
            }
          "
          ref="tableRef"
          :scroll="{ x: 400 }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          class="table"
        >
          <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
          <template #businessTypeName="{ text }">
            <span :title="text">{{ text ?? '-' }}</span>
          </template>
          <template #status="{ text }">
            <div class="status">
              <!-- 1未读 2已读 -->
              <div :class="['status-of', text === 1 ? 'status-no' : 'status-def']"></div>
              <span :title="text">{{ text === 1 ? '未读' : '已读' }}</span>
            </div>
          </template>
          <template #content="{ text }">
            <div class="text-box">
              <span v-if="text.length < 20">{{ text ?? '-' }}</span>
              <a-tooltip v-else>
                <template #title>
                  <span>{{ text }}</span>
                </template>
                <span class="text-ellipsis text-6">{{ text }}</span>
              </a-tooltip>
            </div>
          </template>
          <template #warningTime="{ text }">
            <span :title="text">{{ text ?? '-' }}</span>
          </template>
          <template #messageType="{ text }">
            <!-- 1通知 2预警 -->
            <span :title="text">{{ text === 1 ? '通知' : '预警' }}</span>
          </template>
          <template #action="{ record, index }">
            <!-- 打开弹窗 -->
            <!-- TODO: 增加归档审批通知查看显示条件-->
            <a
              v-if="![4, 6, 17, 28].includes(record.businessType)"
              @click="openCheckModal(record, index)"
              class="table-text-color"
              >查看</a
            >
          </template>
        </a-table>
        <div class="foot" v-if="selectedRowKeys.length > 0">
          <a-button @click.prevent="handleCancel" type="primary">标记为已读</a-button>
        </div>
      </section>
      <cmodal v-model:visible="cmvisible" :currentLoading="currentLoading" :current="current" :hasFoot="false" />
      <modal v-model:visible="tVisible" v-model:loading="tLoading" :current="tCurrent" v-if="tVisible" />
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { warningColumns } from './columns'
import cmodal from './components/checkModal'
import {
  getMessageList,
  getMessageDetails,
  getUnreadInfo,
  changeInfo,
  getDetails,
  getCabinetDetails
} from '@/apis/infoCenter'
import { Modal } from 'ant-design-vue'
import { setTablePageFooterStyle, getEnums } from '@/utils/utils'
import { warningStatusObj, paginationParams } from '@/utils/constData'
import { useRouter } from 'vue-router'
import modal from './components/modal'

const tabList = [
  {
    id: 0,
    name: '全部'
  },
  {
    id: 1,
    name: '未读'
  },
  {
    id: 2,
    name: '已读'
  }
]

const businessTypeList = [
  {
    id: 1,
    name: '标题'
  },
  {
    id: 2,
    name: '类别'
  },
  {
    id: 3,
    name: '详情'
  }
]

export default defineComponent({
  name: 'warningCenter',
  components: {
    cmodal,
    modal
  },
  setup() {
    const inputbox = ref(null)
    const tableRef = ref()
    const router = useRouter()
    const state = reactive({
      searchText: undefined,
      tabType: undefined,
      businessType: 1,
      sealStatus: undefined,
      warningColumns,
      dataSource: [],
      visible: false,
      cmvisible: false,
      current: undefined,
      currentLoading: false,
      pagination: {
        ...paginationParams
      },
      actionType: 'add',
      loading: false,
      processStaffList: [],
      tableLoading: true,
      currentNum: 1,
      activeKey: '0',
      selectedRowKeys: [],
      tVisible: false,
      tCurrent: undefined,
      tLoading: false
    })

    const getList = async () => {
      state.tableLoading = true
      const params = {
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize
      }
      if (state.searchText) {
        params.searchText = state.searchText
        params.searchType = state.businessType // 标题/类别/详情
      }
      if (state.tabType !== 0) {
        // 已读/未读
        params.status = state.tabType
      }
      if (state.activeKey !== '0') {
        //系统/预警
        params.messageType = Number(state.activeKey)
      }
      // const res = await getMessageList(params)
      const res = await getUnreadInfo(params)
      state.dataSource = res.data
      console.log('消息中心列表数据', state.dataSource)
      state.pagination.total = res.totalItem
      state.pagination.index = res.pageIndex
      state.tableLoading = false
      setTablePageFooterStyle(tableRef)
    }
    // 查询
    const searchList = () => {
      console.log('搜索按钮，刷新列表', state.tabType, state.businessType, state.searchText)
      let data = {}
      // 清空前后空格
      if (state.searchText) {
        state.searchText = state.searchText.replace(/\s*/g, '')
      }
      state.pagination.current = !state.searchText && !state.businessType ? state.currentNum : 1
      getList(data)
    }

    const handleTableChange = ({ current, pageSize }) => {
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      getList()
    }
    const openCheckModal = async (current, index) => {
      state.selectedRowKeys = []
      // 通知类的信息跳转页面/预警类的打开弹窗
      console.log('查看消息通知当前点击项11', current, index)
      // const infoList = [1, 2, 7, 8, 16, 17,18,22,24,12,13,14,15,23,25]
      const warnList = [3, 4, 5, 6, 9, 11, 19, 20, 21, 29, 30, 31, 32, 33, 34, 35]
      if (current.businessType === 16 && current.documentStatus === 1) {
        // documentStatus 1、特权用户用印 2、普通用印，
        state.tVisible = true
        state.tLoading = true
        const res = await getDetails({
          documentId: current.documentId
        })
        state.tCurrent = res.data
        state.tLoading = false
      } else if (warnList.includes(current.businessType)) {
        state.cmvisible = true
        state.currentLoading = true
        const params = {
          messagePushId: current.id,
          businessType: current.businessType
        }
        const res = current.hasCabinet ? await getCabinetDetails(params) : await getMessageDetails(params)
        state.current = res.data
        state.currentLoading = false
      } else {
        let path = ''
        let query = {}
        // if ([1, 13, 26].includes(current.businessType)) {
        //   // 待审批/远程审批/补盖待审批
        //   path = '/approval/approved/pendingdetail'
        //   query = {
        //     type: 0,
        //     documentId: current.documentId ?? current.businessId,
        //     taskId: current.dataId,
        //     hasCover: current.businessType === 13
        //   }
        // }
        //  else
        if ([2, 7, 8, 14, 15, 22, 23, 24, 25, 27].includes(current.businessType)) {
          // 跳转申请详情
          //2远程审批/7审批通过/8审批拒绝/14补盖通过/15补盖拒绝/22申请终止/23补盖申请终止/24终止用印/25补盖终止用印/27盖印催办
          path = '/seal/apply/detail'
          query = {
            documentId: current.documentId ?? current.businessId
          }
        } else if ([12].includes(current.businessType)) {
          // 抄送
          path = '/approval/informPeople/detail'
          query = {
            documentId: current.documentId ?? current.businessId,
            processInstanceId: current.dataId
          }
        }
        // else if ([16, 18].includes(current.businessType)) {
        //   // 用印记录
        //   path = '/seal/applyWaitList/detail'
        //   query = {
        //     documentId: current.documentId ?? current.businessId
        //   }
        // }
        else if ([1, 13, 26, 36].includes(current.businessType)) {
          // 待审批/远程审批/补盖待审批
          // 36、归档待审批-归档待审批详情
          path = '/approval/notApproval/pendingdetail'
          query = {
            type: 0,
            taskId: current.dataId,
            dataId: current.businessId,
            processMold: current.processMold,
            hasCover: current.businessType === 13
          }
        }
        // else if ([38].includes(current.businessType)) {
        //   // 38、归档拒绝
        //   path = '/seal/applyCompleteList/detail'
        //   query = {
        //     type: 1,
        //     documentId: current.documentId,
        //     dataId: current.businessId,
        //     processMold: current.processMold
        //   }
        // }
        // else if ([37].includes(current.businessType)) {
        //   // 37、归档通过
        //   path = '/seal/applyArchiveList/detail'
        //   query = {
        //     type: 1,
        //     documentId: current.documentId,
        //     dataId: current.businessId,
        //     processMold: current.processMold
        //   }
        // }
        else if ([16, 18, 37, 38].includes(current.businessType)) {
          // 完成用印提醒16
          // OCR比对提醒18
          // 归档审批通过提醒37
          // 归档审批拒绝提醒38

          // 1,2跳转待用印
          // 3,4,6,7,8跳转已完成
          // 5跳转到已归档
          switch (current.sealStatus) {
            case 1:
            case 2:
              path = '/seal/applyWaitList/detail'
              break
            case 3:
            case 4:
            case 6:
            case 7:
            case 8:
              path = '/seal/applyCompleteList/detail'
              break
            case 5:
              path = '/seal/applyArchiveList/detail'
              break
          }
          query = {
            documentId: current.documentId
          }
        }
        router.push({ path: path, query: query })
      }
      // 标记为已读
      var res = await changeInfo([current.id])
      if (res.success) {
        state.selectedRowKeys = []
        getList()
      }
    }

    onMounted(() => {
      getList()
      // console.log('----5555-----', getEnums('MESSAGE_PUSH'))
    })

    // 切换面板
    const changeActive = () => {
      state.pagination.current = 1
      getList()
    }

    // 列表选中
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
      console.log('选中: ', selectedRowKeys)
    }

    // 重置
    const resetList = () => {
      state.searchText = ''
      state.businessType = 1
      state.pagination.current = 1
      state.tabType = undefined
      console.log('搜索栏置空，刷新列表', state.tabType, state.businessType, state.searchText)
      getList()
    }

    // 标记为已读
    const handleCancel = async () => {
      let ids = []
      state.selectedRowKeys.forEach(item => {
        const map = state.dataSource[item]
        // console.log('mapiiiiiiiiii---', map)
        ids.push(map.id)
      })
      console.log('这是选中的数据，掉用接口后刷新列表', state.selectedRowKeys)
      var res = await changeInfo(ids)
      if (res.success) {
        state.selectedRowKeys = []
        getList()
      }
    }

    return {
      ...toRefs(state),
      openCheckModal,
      searchList,
      handleTableChange,
      warningStatusObj,
      businessTypeList,
      tableRef,
      inputbox,
      changeActive,
      onSelectChange,
      tabList,
      resetList,
      handleCancel
    }
  }
})
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
<style lang="less">
.text-box {
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

/*
 * @version: 1.0
 * @Date: 2024-01-19 17:58:15
 * @LastEditors: 杨慢慢
 */
export const warningColumns = [
  {
    title: '标题',
    dataIndex: 'businessTypeName',
    key: 'businessTypeName',
    align: 'left',
    slots: { customRender: 'businessTypeName' },
    width: 100
  },
  {
    title: '详情',
    dataIndex: 'content',
    key: 'content',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'content' },
    width: 220
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    slots: { customRender: 'status' },
    ellipsis: true,
    width: 100
  },
  {
    title: '时间',
    dataIndex: 'warningTime',
    key: 'warningTime',
    align: 'left',
    slots: { customRender: 'warningTime' },
    ellipsis: true,
    width: 150
  },
  {
    title: '类别',
    dataIndex: 'messageType',
    key: 'messageType',
    align: 'left',
    ellipsis: true,
    slots: { customRender: 'messageType' },
    width: 80
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    slots: { customRender: 'action' },
    fixed: 'right',
    width: 80
  }
]
